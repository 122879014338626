import message from '../../i18n/pt-br';
import { vwoCodeBR } from '../const';

// Публичный ключ от рекапчи. Подставляется в Head Scripts
const grecaptchaPublicKey = '6LdDquspAAAAAGusRydpZSMdsqO201gm2vjwK4df';

const API = {
  REST_API: {
    CMS_REST_API: 'https://cms.ebaconline.com.br/api',
    BILLING_REST_API: 'https://billing.ebac.app',
    LMS_REST_API: 'https://api.lms.ebaconline.com.br',
  },
  ENDPOINTS: {
    METRICS_URL: 'https://valhalla.ebac.app',
    CUSTOMER_IO_URL:
      'https://fkwefind93.execute-api.sa-east-1.amazonaws.com/default/billing_customer-io_get-customer-attributes',
    // Lambda, которая возвращает cloudfront info о пользователе
    cloudFrontInfoUrl: 'https://widgets.ebac.app/cloudfront-viewer-info',
    getPaymentLink: 'https://gdd.ebaconline.com.br/api/get_payment_link',
    getDetailWithPaymentLink:
      'https://billing.ebac.app/offer/detail-with-links',
    webinarVisitorEventsUrl:
      'https://wisggajuuczifmvdtlzwluwpei0urtta.lambda-url.sa-east-1.on.aws/webinar-visitors-webhook',
    webinarsCreateLeadUrlAws:
      'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/br/subscribe/webinar',
    showcaseApiUrl: 'https://cms.ebaconline.com.br/api/public/v2/showcase',
    checkSubscriptionsLink:
      'https://api.lms.ebaconline.com.br/subscriptions/is-mine',
    leadFormUrl:
      'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/queue/br/subscribe/course',
    saleUrl:
      'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/queue/br/subscribe/course',
    promocodeUrl: 'https://cms.ebaconline.com.br/api/shopping-cart',
    wordPressPosts: 'https://ebaconline.com.br/blog/wp-json/wp/v2/posts',
    feedbackUrl:
      'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/br/subscribe/register',
  },
};

const SECURITY = {
  GRECAPTCHA: {
    grecaptchaPublicKey,
    grecaptchaEnabled: true,
  },
};

const ANALYTICS = {
  experiments: {
    'black-friday': {
      defaultValue: 'type-one',
      page: '/cursos',
    },
    experimental: {
      defaultValue: 'type-one',
      page: '/ebac-experimental',
    },
  },
  gtm: {
    debug: false,
    id: 'GTM-MD8LLQM',
    autoInit: true,
    enabled: true,
    pageTracking: true,
    scriptDefer: true,
    respectDoNotTrack: false,
  },
};

const LOCALIZATION = {
  I18N: {
    localeName: 'pt-br',
    utcOffset: -3,
    message,
    numberFormat: {
      currency: {
        style: 'currency',
        currency: 'BRL',
        currencyDisplay: 'symbol',
      },
      currencyNoCents: {
        style: 'currency',
        currency: 'BRL',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      decimal: {
        style: 'decimal',
      },
    },
    defaultPhoneMask: 'bm',
  },
  SEO: {
    hreflang: 'pt-BR',
    href: 'https://ebaconline.com.br',
    code: 'br',
  },
};

const MARKETING = {
  SALE: {
    // Slug реферальных страниц
    saleReferral: [
      {
        slug: 'referral',
        grsfCampaignId: 'kx06xq',
      },
      {
        slug: 'ganhe-com-ebac',
        grsfCampaignId: 'n780r5',
      },
    ],
    // Slug распродажи, на котором будет отображено колесо фортуны
    fortuneWheelSlug: [
      'natal-wheel',
      'test-sale-2',
      'fortune-wheel',
      'volta-as-aulas',
    ],
    /**
     * Список распродаж, для которых автоматически активируется промокод
     * Если readonly: true - активированный купон нельзя стереть
     * или изменить
     *
     * Пример:
     * {
     *  saleSlug: 'junho-da-virada',
     *  code: '230613_dvefp4cf',
     *  readonly: false,
     * },
     * */
    autoFillPromoCodeForSales: [
      {
        saleSlug: 'loyalme',
        code: 'PRESENTEDOCLUBE',
        readonly: true,
      },
      {
        saleSlug: 'anhanguera-marketing',
        code: 'cogna2025',
        readonly: true,
      },
      {
        saleSlug: 'anhanguera-programming',
        code: 'cogna2025',
        readonly: true,
      },
      {
        saleSlug: 'anhanguera-arts',
        code: 'cogna2025',
        readonly: true,
      },
      {
        saleSlug: 'anhanguera-upskill',
        code: 'cogna2025',
        readonly: true,
      },
    ],
    // Images берутся из assets/images/saleNew/unique/<file>
    saleNewImages: [
      {
        slug: 'analista-de-dados',
        name: 'analista-de-dados.jpg',
      },
      {
        slug: 'cientista-de-dados',
        name: 'cientista-de-dados.jpg',
      },
      {
        slug: 'designer-de-interiores',
        name: 'designer-de-interiores.jpg',
      },
      {
        slug: 'designer-grafico',
        name: 'designer-grafico.jpg',
      },
      {
        slug: 'full-stack-java',
        name: 'full-stack-java.jpg',
      },
      {
        slug: 'full-stack-python',
        name: 'full-stack-python.jpg',
      },
      {
        slug: 'it-start',
        name: 'it-start.jpg',
      },
      {
        slug: 'mkt-start',
        name: 'mkt-start.jpg',
      },
      {
        slug: 'uxui-designer',
        name: 'uxui-designer.jpg',
      },

      {
        slug: 'copywriting',
        name: 'copywriting.jpg',
      },
      {
        slug: 'design-grafico',
        name: 'design-grafico.jpg',
      },
      {
        slug: 'fashion-business',
        name: 'fashion-business.jpg',
      },
      {
        slug: 'fashion-design',
        name: 'fashion-design.jpg',
      },
      {
        slug: 'fotografia',
        name: 'fotografia.jpg',
      },
      {
        slug: 'marketing-digital',
        name: 'marketing-digital.jpg',
      },
      {
        slug: 'personal-stylist',
        name: 'personal-stylist.jpg',
      },
      {
        slug: 'power-bi',
        name: 'power-bi.jpg',
      },
      {
        slug: 'roteiro',
        name: 'roteiro.jpg',
      },
    ],
  },
  saleBanner: {
    endDate: '2023-10-07T02:59:00Z', // пример 2022-08-27T02:59:00Z - до 26 августа включительно
    eternal: true, // вечная распродажа? если да, то endDate можно не заполнять (если ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, ПН, то до ПН)
    eternalV2: false, // вечная распродажа? если да, то endDate можно не заполнять (если ПН, ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, то до ВС)
    name: '110923', // название распродажи, формат ddmmyy
    link: 'https://ebaconline.com.br/sale/invista-em-voce', // ссылка на распродажу
  },
  sourceId: {
    index: 8,
    sale: 132,
    saleBasket: 131,
  },
};

const COMMUNICATION = {
  SOCIAL: {
    phone: '+55 (11) 3030-3200',
    phoneLink: 'tel:+551130303200',
    whatsApp: '+55 (11) 4200-2991',
    whatsAppLink: 'https://wa.me/551142002991',
    email: 'suporte@ebaconline.com.br',
    emailLink: 'mailto:suporte@ebaconline.com.br',
    instagramLink: 'https://www.instagram.com/e.b.a.c/',
    facebookLink: 'https://www.facebook.com/escolabritanicadeartescriativas/',
    youtubeLink: 'https://www.youtube.com/EBACoficial',
    linkedInLink:
      'https://linkedin.com/school/ebac-escola-britanica-artes-criativas',
    tiktok: '',
  },
  WEBINARS: {
    withWebinars: true,
    webinarsUrl: '/webinars',
  },
  BLOG: {
    withBlog: true,
    tildaBlog: false,
    blogUrl: 'https://ebaconline.com.br/blog',
    getBlogsUrl: '/public/getBlogPosts',
    blogLinks: [
      {
        href: '/blog#!/tab/238198275-2',
        key: 'template.design',
      },
      {
        href: '/blog#!/tab/238198275-3',
        key: 'template.audioVisual',
      },
      {
        href: '/blog#!/tab/238198275-4',
        key: 'template.marketing',
      },
      {
        href: '/blog#!/tab/238198275-5',
        key: 'template.scheduleAndDate',
      },
      {
        href: '/blog#!/tab/238198275-6',
        key: 'template.games',
      },
    ],
  },
  // B2B: {
  //   b2bLink: 'https://ebaconline.com.br/sobre/para-empresas',
  //   b2bLinks: {
  //     corporateEducation: {
  //       url: 'https://ebaconline.com.br/para-empresas/corporate-education',
  //     },
  //     challenges: { url: 'https://ebaconline.com.br/para-empresas/challenges' },
  //   },
  // },
  LINKS: {
    externalVacanciesLink: 'https://ebac.gupy.io/',
    ourStudentsLink: 'https://ebaconline.com.br/depoimentos',
    massMediaLink: 'https://ebaconline.com.br/midia',
    studentReviewsLink: 'https://ebaconline.com.br/depoimentos',
    EBACInMedia: 'https://ebaconline.com.br/midia',
    // URL главной страницы
    lmsUrl: 'https://lms.ebaconline.com.br/',
    // URL страницы login
    lmsLoginUrl: 'https://lms.ebaconline.com.br/login/',
  },
};

const LEGAL = {
  DOCUMENTS: {
    documents: {
      serviceAgreementAndPrivacyPolicy:
        'https://ebaconline.com.br/upload/cms/service_agreement_and_privacy_policy.pdf',
      linkToMx: {
        attrs: { title: 'Ebac.mx' },
        link: 'https://ebac.mx/cursos',
      },
    },
    footerDocuments: ['serviceAgreementAndPrivacyPolicy', 'linkToMx'],
    formDocument: 'serviceAgreementAndPrivacyPolicy',
  },
  PAGE_404: {
    additionalText404: true,
    links404: [
      {
        key: 'courses',
        link: '/cursos?types=course',
      },
      {
        key: 'professions',
        link: '/cursos?types=profession',
      },
      {
        key: 'freeCourses',
        link: '/cursos?free=true',
      },
      {
        key: 'jobGuarantee',
        link: '/cursos?jobGuarantee=true',
      },
      {
        key: 'upcomingEvents',
        link: '/webinars',
      },
      {
        key: 'blogArticles',
        link: 'https://ebaconline.com.br/blog',
      },
      {
        key: 'aboutUs',
        link: '/about-us',
      },
    ],
  },
};

const HEAD = {
  // Global head
  head: {
    lang: 'pt',
    meta: [
      { name: 'msapplication-TileColor', content: '#ffffff' },
      { name: 'msapplication-config', content: '/favicons/browserconfig.xml' },
    ],
    link: [
      {
        rel: 'shortcut icon',
        type: 'image/x-icon',
        href: '/favicon.ico',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/apple-touch-icon.png',
      },
      {
        rel: 'icon',
        sizes: '32x32',
        type: 'image/png',
        href: '/favicon-32x32.png',
      },
      {
        rel: 'icon',
        sizes: '16x16',
        type: 'image/png',
        href: '/favicon-16x16.png',
      },
      {
        rel: 'manifest',
        crossOrigin: 'use-credentials',
        href: '/favicons/site.webmanifest',
      },

      // Преконнект сервиса a/b тестов
      {
        rel: 'preconnect',
        href: 'https://dev.visualwebsiteoptimizer.com',
      },
    ],
    script: [
      // {
      //   src: 'https://cdn.popupsmart.com/bundle.js',
      //   body: true,
      //   async: true,
      //   defer: true,
      //   'data-id': 11931
      // }

      {
        src: 'https://cdn.jsdelivr.net/npm/vue@2.6.14',
        body: true,
        defer: true,
      },
      {
        src: 'https://widgets.ebac.app/cookie-consent/br/cookie-consent-init.js',
        body: true,
        defer: true,
      },
      {
        src: 'https://widgets.ebac.app/cookie-consent/br/cookie-consent.min.js',
        body: true,
        defer: true,
      },

      // Подключение сервиса a/b тестов
      {
        type: 'text/javascript',
        id: 'vwoCode',
        innerHTML: vwoCodeBR,
      },

      // Подключение Google Recaptcha v3
      {
        src: `https://www.google.com/recaptcha/api.js?render=${grecaptchaPublicKey}`,
        body: true,
        defer: true,
      },
    ],
    __dangerouslyDisableSanitizers: ['script'],
  },
  bodyScripts: [],
  // head скрипты страницы "центр карьеры студентов"
  careerCenterStudentsScripts: [
    // {
    //   innerHTML: `
    //     var yWidgetSettings = {
    //       buttonColor : '#1C84C6',
    //       buttonPosition : 'bottom right',
    //       buttonAutoShow : true,
    //       buttonText : 'Online booking',
    //       formPosition : 'right'
    //     };
    //   `,
    //   type: 'text/javascript',
    // },
  ],
};

export default {
  ...API.REST_API,
  ...API.ENDPOINTS,

  ...SECURITY.GRECAPTCHA,

  ...ANALYTICS,

  ...LOCALIZATION.I18N,
  ...LOCALIZATION.SEO,

  ...MARKETING.SALE,
  saleBanner: MARKETING.saleBanner,
  sourceId: MARKETING.sourceId,

  ...COMMUNICATION.SOCIAL,
  ...COMMUNICATION.WEBINARS,
  ...COMMUNICATION.BLOG,
  ...COMMUNICATION.B2B,
  ...COMMUNICATION.LINKS,

  ...LEGAL.DOCUMENTS,
  ...LEGAL.PAGE_404,

  ...HEAD,

  // Домен страны
  domain: 'ebaconline.com.br',
  // Домен, который заменяет домен у некоторых ссылок
  // приходящих из cms
  newDomainName: '',
  // Наличие гарантии трудоустройства
  jobGuarantee: true,
  // Отображать ли checkbox принятия политики конфиденциальности
  // у форм
  formWithCheckbox: true,
};
